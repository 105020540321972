import { AppMainService, ChatMessage, Project } from "../types";
import Show from "../models/Show";
import Multiplex from "../models/Multiplex";
import config from "../configs/firebase-service.config";
import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import Pledge from "../models/Pledge";
import "firebase/firestore";

import { GENERIC_FETCH_ALERT } from "../messages/error";

import UserData from "../models/UserData";
import { pseudoRandomBytes } from "crypto";
import {DashboardLocalCount, MultiplexDashboard} from "../models/Dashboard";

const firebaseConfig = config();
!firebase.apps.length
  ? firebase.initializeApp(firebaseConfig.sdkCredentials)
  : firebase.app();

const $firestore = firebase.firestore();

if (firebaseConfig.envName === "LOCAL") {
  $firestore.useEmulator(
    firebaseConfig.emulators.firestore.host,
    firebaseConfig.emulators.firestore.port
  );
} else {
  //   firebase.analytics();
}

const getHydratedShowFromFetch = (show: Record<string, any>) => {
  return new Show(
    show.id,
    show.data().name,
    show.data().urlSlug,
    show.data().isPublished,
    show.data().isOnPlace,
    show.data().isOnRemote,
    show.data().place,
    show.data().subtitle,
    new Date(show.data().startDate.seconds * 1000),
    new Date(show.data().endDate.seconds * 1000),
    show.data().description,
    show.data().endMessage,
    show.data().endButtonText,
    show.data().endButtonUrl,
    show.data().comingButtonText,
    show.data().comingButtonUrl,
    show.data().pastButtonText,
    show.data().pastButtonUrl,
    show.data().coverUrl,
    show.data().logoUrl,
    show.data().isStreamingEnabled,
    show.data().youtubeStreamId,
    show.data().hasPledgesPresets,
    show.data().firstPledgeAmount,
    show.data().secondPledgeAmount,
    show.data().thirdPledgeAmount,
    show.data().fourthPledgeAmount,
    show.data().pledgesCurrency,
    show.data().hasFreePledgeAmount,
    show.data().hasTaxReceiptAvailable,
    show.data().hasCustomPledgeAmounts,
    show.data().isFirstnamePresetOn,
    show.data().isFirstnamePresetRequired,
    show.data().isLastnamePresetOn,
    show.data().isLastnamePresetRequired,
    show.data().isPhonePresetOn,
    show.data().isPhonePresetRequired,
    show.data().isAddressFirstLinePresetOn,
    show.data().isAddressFirstLinePresetRequired,
    show.data().isAddressSecondLinePresetOn,
    show.data().isAddressSecondLinePresetRequired,
    show.data().isZipcodePresetOn,
    show.data().isZipcodePresetRequired,
    show.data().isCityPresetOn,
    show.data().isCityPresetRequired,
    show.data().isCountryPresetOn,
    show.data().isCountryPresetRequired,
    show.data().customRegistrationsList,
    show.data().currentProjectId,
    show.data().isClosed,
    show.data().totalPledgesAmount,
    show.data().hasNoProjects,
    show.data().customPledgeAmountsCount,
    show.data().averagePledgesAmount,
    show.data().allPledgesCount,
    show.data().totalUsersCount,
    show.data().forcedEndingAmount,
    show.data().globalShowGoal,
    show.data().isLnbc,
    show.data().backgroundColor,
    show.data().fontColor,
    show.data().colorPledge,
    show.data().backgroundColorPledge,
    show.data().backgroundColorPledgeBeyond,
    show.data().backgroundColorLogo,
    show.data().isPseudoPresetOn,
    show.data().isPseudoPresetRequired,
    show.data().multiplexId
  );
};

const getHydratedMultiplexFromFetch = (multiplex: any) => {
  return new Multiplex(
    multiplex.id,
    multiplex.data().name,
    multiplex.data().subtitle,
    new Date(multiplex.data().startDate.seconds * 1000),
    multiplex.data().logoUrl,
    multiplex.data().participatingShows,
    multiplex.data().pledgesCurrency,
    multiplex.data().totalPledgesAmount,
    multiplex.data().averagePledgesAmount,
    multiplex.data().allPledgesCount,
    multiplex.data().totalUsersCount,
    multiplex.data().globalMultiplexGoal,
    multiplex.data().currentDashboardId,
    multiplex.data().backgroundColorDark,
    multiplex.data().backgroundColorLight,
    multiplex.data().fontColor,
    multiplex.data().fontColorHighlight,
    multiplex.data().displayProgressBar,
  );
};

class FirebaseService implements AppMainService {
  listenToAllShows(listenerCallback: Function) {
    const allShowsListener = $firestore
      .collection("shows")
      .where("isPublished", "==", true)
      .onSnapshot((snapshot) => {
        const shows = [] as Show[];
        snapshot.docs.forEach((show: Record<string, any>) => {
          shows.push(getHydratedShowFromFetch(show));
        });
        listenerCallback(shows);
      });
    return allShowsListener;
  }
  hasShowEnded(showId: string): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .get()
        .then((doc) => {
          if (doc != undefined) {
            res(doc?.data()?.isClosed ? true : false);
          } else {
            res(true);
          }
        })
        .catch((e) => {
          console.log(e);
          rej(GENERIC_FETCH_ALERT);
        });
    });
  }
  getShowBySlug(slug: string): Promise<Show> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .where("urlSlug", "==", slug)
        .get()
        .then((snapshot) => {
          const dataList: Show[] = [];
          snapshot.docs.forEach((doc: Record<string, any>) =>
            dataList.push(getHydratedShowFromFetch(doc))
          );
          res(dataList[0]);
        })
        .catch((e) => {
          console.log(e);
          rej(GENERIC_FETCH_ALERT);
        });
    });
  }
  listenToLiveShow(showId: string, listenerCallback: Function) {
    const liveShowListener = $firestore
      .collection("shows")
      .doc(showId)
      .onSnapshot((doc) => {
        listenerCallback(getHydratedShowFromFetch(doc));
      });
    return liveShowListener;
  }
  listenToLiveProject(
    showId: string,
    projectId: string,
    listenerCallback: Function
  ) {
    const liveProjectListener = $firestore
      .collection("shows")
      .doc(showId)
      .collection("projects")
      .doc(projectId)
      .onSnapshot((doc) => {
        listenerCallback({
          id: doc?.id,
          name: doc?.data()?.name,
          logoUrl: doc?.data()?.logoUrl,
          goal: doc?.data()?.goal,
          position: doc?.data()?.position,
          totalPledgesAmount: doc?.data()?.totalPledgesAmount,
          backgroundColorLogo: doc?.data()?.backgroundColorLogo
        });
      });
    return liveProjectListener;
  }
  listenToShowProjects(showId: string, listenerCallback: Function) {
    const oneShowProjectsListener = $firestore
      .collection("shows")
      .doc(showId)
      .collection("projects")
      .orderBy("position")
      .onSnapshot((snapshot) => {
        const projects = [] as Project[];
        snapshot.docs.forEach((show: Record<string, any>) => {
          projects.push({
            id: show.id,
            position: show.data().position,
            name: show.data().name,
            goal: show.data().goal,
            logoUrl: show.data().logoUrl,
            totalPledgesAmount: show.data().totalPledgesAmount,
            backgroundColorLogo: show.data().backgroundColorLogo
          });
        });
        listenerCallback(projects);
      });
    return oneShowProjectsListener;
  }
  listenToLiveChatMessages(showId: string, listenerCallback: Function) {
    const liveChatMessagesListener = $firestore
      .collection("shows")
      .doc(showId)
      .collection("chat_messages")
      .where("isDisabled", "==", false)
      .onSnapshot((snapshot) => {
        const liveChatMessagesList: ChatMessage[] = [];
        snapshot.docs.forEach((chatMessage) => {
          liveChatMessagesList.push({
            id: chatMessage.id,
            username: chatMessage.data().username,
            content: chatMessage.data().content,
            date: new Date(chatMessage?.data()?.date?.seconds * 1000),
            isAdmin: chatMessage.data().isAdmin,
            isDisabled: chatMessage.data().isDisabled,
            pledgeAmount: chatMessage.data().pledgeAmount,
            pseudo: chatMessage.data().pseudo
          });
        });
        listenerCallback(liveChatMessagesList);
      });
    return liveChatMessagesListener;
  }
  saveUserDataOnRemote(userData: UserData): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(userData.eventId)
        .collection("users")
        .doc(userData.email)
        .set(
          {
            ...userData
          },
          { merge: true }
        )
        .then(() => {
          res(true);
        })
        .catch((e) => {
          rej(false);
        });
    });
  }
  sendChatMessage(
    showId: string,
    user: UserData,
    message: string,
    pledgeAmount?: number
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      const messageId = uuidv4();
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("chat_messages")
        .doc(messageId)
        .set({
          username: user.liveHashId,
          content: message,
          date: new Date(),
          isAdmin: false,
          isDisabled: false,
          pledgeAmount: pledgeAmount || 0,
          pseudo: user.pseudo
        })
        .then(() => {
          $firestore
            .collection("shows")
            .doc(showId)
            .collection("chat_messages_private")
            .doc(messageId)
            .set({
              username: user.liveHashId,
              content: message,
              date: new Date(),
              isAdmin: false,
              isDisabled: false,
              pledgeAmount: pledgeAmount || 0,
              userInfos: user.payload,
              pseudo: user.pseudo
            })
            .then(() => {
              res(true);
            })
            .catch((err) => {
              console.log(err);
              rej(false);
            });
        })
        .catch((err) => {
          console.log(err);
          rej(false);
        });
    });
  }
  sendPledge(showId: string, pledge: Pledge): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("pledges")
        .doc(pledge.id)
        .set({ ...pledge.payload }, { merge: true })
        .then(() => {
          res(true);
        })
        .catch((e) => {
          rej(false);
        });
    });
  }
  fetchCustomAmounts(eventId: string, userEmail: string): Promise<any> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(eventId)
        .collection("custom_user_amounts")
        .doc(userEmail)
        .get()
        .then((document) => {
          if (!document.exists) {
            res(null);
          } else {
            res({
              firstAmount: document.data()?.firstAmount,
              secondAmount: document.data()?.secondAmount,
              thirdAmount: document.data()?.thirdAmount,
              fourthAmount: document.data()?.fourthAmount,
              freeAmount: document.data()?.freeAmount
            });
          }
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }

  listenToLiveMultiplex(multiplexId: string, listenerCallback: Function) {
    const liveMultiplexListener = $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .onSnapshot((doc) => {
        listenerCallback(getHydratedMultiplexFromFetch(doc));
      });
    return liveMultiplexListener;
  }

  getMultiplexBySlug(slug: string): Promise<Multiplex> {
    return new Promise((res, rej) => {
      $firestore
        .collection("multiplexes")
        .where(firebase.firestore.FieldPath.documentId(), "==", slug)
        .get()
        .then((snapshot) => {
          const dataList: Multiplex[] = [];
          snapshot.docs.forEach((doc: Record<string, any>) =>
            dataList.push(getHydratedMultiplexFromFetch(doc))
          );
          res(dataList[0]);
        })
        .catch((e) => {
          console.log(e);
          rej(GENERIC_FETCH_ALERT);
        });
    });
  }

  listenToMultiplexDashboards(multiplexId: string, listenerCallback: Function) {
    const dashboardsListener = $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .collection("dashboards")
      .onSnapshot((snapshot) => {
        const dashboards = [] as MultiplexDashboard[];
        snapshot.docs.forEach((dashboard: Record<string, any>) => {
          dashboards.push({
            id: dashboard.id,
            name: dashboard.data().name,
            totalPledgesAmount: dashboard.data().totalPledgesAmount,
            goal: dashboard.data().goal,
            logoUrl: dashboard.data().logoUrl,
            selectedProjects: dashboard.data().selectedProjects
          });
        });
        listenerCallback(dashboards);
      });
    return dashboardsListener;
  }
  listenToSelectedDashboardLocalities(
    multiplexId: string,
    dashboardId: string,
    callback: Function
  ) {
    $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .collection("dashboards")
      .doc(dashboardId)
      .collection("localCounts")
      .where("selected", "==" , true)
      .onSnapshot((snapshot) => {
        const snapshotList = [] as DashboardLocalCount[]
        snapshot.docs.forEach((localCount: Record<string, any>) => {
          snapshotList.push({
            id: localCount.id,
            place: localCount.data().place,
            totalPledgesAmount: localCount.data().totalPledgesAmount,
            selected: localCount.data().selected
          });
        });
        callback(snapshotList);
      });
  }

  listenToTopDashboardLocalities(
    multiplexId: string,
    dashboardId: string,
    callback: Function
  ) {
    $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .collection("dashboards")
      .doc(dashboardId)
      .collection("localCounts")
      .orderBy("totalPledgesAmount", "desc")
      .onSnapshot((snapshot) => {
        const snapshotList = [] as DashboardLocalCount[]
        snapshot.docs.forEach((localCount: Record<string, any>) => {
          snapshotList.push({
            id: localCount.id,
            place: localCount.data().place,
            totalPledgesAmount: localCount.data().totalPledgesAmount,
            selected: localCount.data().selected
          });
        });
        callback(snapshotList);
      });
  }

  getMultiplexShows(slugs: string[]): Promise<Show[]> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .where(firebase.firestore.FieldPath.documentId(), "in" , slugs)
        .get()
        .then((snapshot) => {
          const dataList: Show[] = [];
          snapshot.docs.forEach((doc: Record<string, any>) => {
            dataList.push(getHydratedShowFromFetch(doc))
          });
          res(dataList);
        })
        .catch((e) => {
          console.log(e);
          rej(GENERIC_FETCH_ALERT);
        });
    });
  }
}

export default FirebaseService;
